<template>
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div id="main-content">
                <h4>Coming soon</h4>
                <h1 class="display-1">Neotex Scout</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>


<style scoped>
  .container {
    color: #fff;
  }
  h4 {
    font-size: 2rem;
  }
  #main-content {
    margin-top: 200px;
  }
</style>